import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useShoppingCart } from './ShoppingCartContext';
import { removeSpecialCharacters } from '../../common/functions'
import ProductService from "api/precision/ProductService";
import moment from 'moment';
import "./cart.css";
import ZoomBox from './ZoomBox';


export default function Product() {
  const navigation = useNavigate();
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const productString = searchParams.get('product');

  const { productId } = useParams(0);
  // Parse the JSON string back into an object
  // const product = JSON.parse(decodeURIComponent(productString));
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [packageQnty, setPackageQnty] = useState(50);


  const [qty, setQty] = useState(1); // State to track quantity
  const [qtyError, setQtyError] = useState(false); // State to track quantity error
  const [validation, validationChange] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleLinkClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    document.title = 'Products';
    ProductService.query(productId)
      .then((response) => {
        setSelectedProduct(response.data.data);
        setPackageQnty(response.data.data.package_quantity);
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  }, [productId]);

  useEffect(() => {
    selectedProduct && setQty(totalItem(selectedProduct.id) == 0 ? selectedProduct.min_quantity : totalItem(selectedProduct.id));
  }, [selectedProduct]);

  useEffect(() => {
    document.title = 'Product Detail'; // Set your desired page title here
  }, []);

  const handleAddToCart = (product, quantity) => {
    if (quantity.length === 0 || quantity === "0") {
      setQtyError(true);
    }
    else {
      addToCart(product, quantity);
      setQty('');
      navigation('/cart/preview/');
    }
  };

  const handleQuantityChange = (e) => {
    const newQty = e.target.value;
    setQtyError(false);
    validationChange(false);

    if (newQty < selectedProduct.min_quantity) {
      setQty(selectedProduct.min_quantity);
      validationChange(true);
    }
    else if (newQty > selectedProduct.max_quantity) {
      setQty(selectedProduct.max_quantity);
      validationChange(true);
    }
    else {
      setQty(newQty);
    }

    // Clear the error when quantity changes
    // validationChange(true);
  };
  const handleRemoveItem = (itemId) => {
    removeFromCart(itemId);
    setQty(selectedProduct.min_quantity);
  };
  const handleImageError = (e) => {
    e.target.src = '/dist/img/noimages.png'; // Provide the path to your default or empty image
  };

  const { addToCart, incrementQuantity, decrementQuantity, totalItem, totalQty, removeFromCart } = useShoppingCart();

  return (
    <>
      {selectedProduct && (
        <section className="new_dog_owner_section">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="new_dog_owner_main">
                  <h2>{selectedProduct.name}</h2>
                  <p>{selectedProduct.additional_info}</p>
                  <p>&nbsp;</p>
                  <form id="quantity_main_owner" action="#" method="post">
                    <div className="col-lg-12 row">

                      {/* {totalItem(selectedProduct.id) === 0 && ( */}
                      <div className="col-lg-7">
                        <div className="quantity_main__inner">
                          <label htmlFor="quantity">{`Quantity (in packs of ${packageQnty})*`}</label>
                          <input
                            className={`quantity ${qtyError ? "error" : ""}`}
                            type="number"
                            id="quantity"
                            name="quantity"
                            value={qty}
                            onChange={handleQuantityChange}
                          />
                          <div className="" style={{ "color": "#0047bb" }}>{`*Actual Inventory: ${selectedProduct.actual_inventory} Packs`}</div>
                        </div>

                      </div>

                      <div className="col-lg-5" style={{ "marginTop": "-5px" }}>
                        <br></br>
                        <div className="quantity_main__inner" >
                          <input
                            className="quentity_add my-3"
                            style={{ "paddingTop": "10px", "paddingBottom": "10px" }}
                            type="button"
                            value="Add To Cart"
                            onClick={() =>
                              handleAddToCart({ ...selectedProduct }, qty)
                            }
                          ></input>
                        </div>
                      </div>
                    </div>
                    <label>
                      {validation && (
                        <h6 className="text-danger">Quantity must be between {selectedProduct.min_quantity} and {selectedProduct.max_quantity}.</h6>
                      )}
                    </label>
                  </form>

                  <p className="order_info" style={{ "fontSize": "1rem", "lineHeight": "1.3rem" }}><i>{`* Minimum Order ${selectedProduct.min_quantity} Packs of ${packageQnty}`}</i></p>
                  <p className="order_info" style={{ "fontSize": "1rem", "lineHeight": "1.3rem" }}><i>{`* Maximum Order ${selectedProduct.max_quantity} Packs of ${packageQnty}`}</i></p>
                  <br />
                  <p className="order_info Link" style={{ "fontSize": "1.1rem", "lineHeight": "1.3rem" }}>
                    Please contact Nationwide if you require more than the
                    maximum order: {" "}
                    <a href="mailto:<clarson@nationwide.com>">
                      clarson@nationwide.com
                    </a>
                  </p>
                  <br />
                  <p className="order_info" style={{ "fontSize": "1rem !important", "lineHeight": "1.3rem" }}>
                    <span style={{ "fontSize": "1rem !important", "lineHeight": "1.3rem" }}>
                      Estimated Shipping Date:**</span><br />
                    <label style={{ "fontSize": "1rem", }}>{moment(selectedProduct.estimate_delivery_date).format('MM/DD/YYYY')}</label>
                  </p>
                  <br />
                  <p className="order_info Link" style={{ "fontSize": "1rem", "lineHeight": "1.3rem" }} >
                    <em style={{ "lineHeight": "1.3rem" }}>**Estimated Shipping Date is based on a day cut off time of {selectedProduct.cutoff_time} pm EST</em>
                  </p>
                  <br /><br />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <figure className="text-center">
                  <img
                    style={{ "cursor": "pointer" }}
                    onClick={handleLinkClick}
                    atl="Product Image"
                    className="img-fluid"
                    src={"/dist/img/product/zm_" + selectedProduct.id + ".jpg"}
                  />
                  <figcaption className="fs-10 text-muted">Click image to zoom</figcaption>
                </figure>
              </div>
            </div>
          </div>

          <ZoomBox
            srcSmall={"/dist/img/product/zm_" + selectedProduct.id + ".jpg"}
            srcLarge={"/dist/img/product/zm_dt_" + selectedProduct.id + ".jpg"}
            showModal={showModal}
            handleCloseModal={handleCloseModal} />
        </section>
      )}
    </>
  );
}
